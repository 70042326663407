import React from "react"
import Seo from "../../components/Core/Seo"
import Layout from "../../components/Layout/Layout"
import colors from "../../styles/colors"
import ResetPassword from "../../components/AccountContent/ResetPassword"

const ResetPasswordPage = ({ location }) => {
  const path = location.pathname
  return (
    <Layout dark bg={colors.white}>
      <Seo
        title="Reset Password Page"
        description="In Good Company Reset Password Page"
        fullTitle="Reset Password Page"
        path={path}
      />
      <ResetPassword />
    </Layout>
  )
}

export default ResetPasswordPage
