import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PageWrapper from "./PageWrapper"
import { AccountButtonDark, AccountButtonLightLink, H2 } from "./AccountStyles"
import AccountInputField from "./AccountInputField"
import { useUserContext } from "../../context/UserContext"
import {
  validateFormFields,
} from "../../services/account/popupFormHelper"
import { resetPassword, loginUser } from "../../services/user/user"
import ErrorPopup from "./ErrorPopup"
import fonts from "../../styles/fonts"
import { isBrowser } from "../../services/browser"

const Info = styled.p`
  font-size: 0.889rem;
  line-height: 1.5;
  color: #1c1a17;
  margin: 0;
  margin-bottom: 2.5rem;
  ${fonts.openSansRegular};
`
const ButtonWrapper = styled.div`
  margin: 1.5rem 0;
`

const passwordMatchError = "Passwords do not match"
const shortPasswordError = "Password must be at least 10 characters."

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: "",
    password2: "",
  })
  const [fieldErrors, setFieldErrors] = useState({
    password: "",
    password2: "",
  })
  const [formError, setFormError] = useState(null)

  useEffect(() => {
    if (isBrowser()) {
      if (
        !window.location.search.includes("key=") ||
        !window.location.search.includes("email=")
      ) {
        window.location.href = "/"
      }
    }
  }, [])

  useEffect(() => {
    let errorTimeOut
    if (formError) {
      errorTimeOut = setTimeout(() => {
        setFormError(null)
      }, 3000)
    }
    return () => {
      clearTimeout(errorTimeOut)
    }
  }, [formError])

  const handleChange = (name, value) => {
    let updatedFormData = formData

    updatedFormData[name] = value
    setFormData(updatedFormData)
  }

  const validatePasswords = () => {
    if (formData["password"] !== formData["password2"]) {
      const newErrors = { ...fieldErrors, password2: passwordMatchError }
      setFieldErrors(newErrors)
      return true
    }
    if (formData.password.length < 10) {
      const newErrors = { ...fieldErrors, password: shortPasswordError }
      setFieldErrors(newErrors)
      return true
    }
    return false
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError(null)
    setFieldErrors({ password: "", password2: "" })
    let hasError = validateFormFields(formData, fieldErrors, setFieldErrors)

    if (formData.password.trim().length > 0 && formData.password2.trim().length) {
      const hasPasswordError = validatePasswords()
      if (hasPasswordError) {
        hasError = true
      }
    }

    if (hasError) {
      return
    }

    try {
      let key = null
      let email = null
      if (isBrowser()) {
        email = window.location.search.split("=")[1].slice(0, -4)
        key = window.location.search.split("=")[2]
      }
      const res = await resetPassword(formData.password, key)

      if (res && res.success === true) {
        window.location.href = "/account/login"
      } else {
        throw Error("An error occured.")
      }
    } catch (error) {
      if (error === "Invalid `password_reset_key`, key not found") {
        setFormError("Password reset link is expired")
      } else {
        setFormError("An error occured.")
      }
      console.error(error)
    }
  }

  return (
    <PageWrapper>
    <H2>Change your password</H2>
    <Info>Please enter your new password.</Info>
    <form onSubmit={handleSubmit} novalidate>
      <AccountInputField
        data={formData}
        name="password"
        placeholder="Password"
        handleChange={handleChange}
        type="password"
        error={fieldErrors.password}
        label="Password"
        information="Must include a minimum of 10 characters."
      />
      <AccountInputField
        data={formData}
        name="password2"
        placeholder="Re-type Password"
        handleChange={handleChange}
        type="password"
        error={fieldErrors.password2}
      />
      <ButtonWrapper>
        <AccountButtonDark type="submit">
        Change Password
        </AccountButtonDark>
      </ButtonWrapper>
    </form>
    {formError && (
      <ErrorPopup>
        <span>{formError}</span>
      </ErrorPopup>
    )}
  </PageWrapper>
  )
}

export default ResetPassword
